.manage-accounts-page {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.account-tbody {
  position: fixed;
  overflow-y: scroll;
  right: 60px;
  left: 60px;
  height: 65%;
}

.account-pagination {
  position: fixed;
  right: 0px;
  bottom: 20px;
  left: 0px;
}

.sortable:hover {
  cursor: default;
  background-color: #c5cae9;
}

.unsortable:hover {
  cursor: default;
}
