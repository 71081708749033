.control-panel {
  display: inline-block;
  position: fixed;
  width: 100%;
  height: 51px;
  top: 0;
  border-bottom: 1px solid #e0e0e0;
  z-index: 3;
}

.control-panel-btn {
  height: 50px;
}

.dropdown-item:hover {
  background-color: #23272b;
}

.clickable-alert:hover {
  cursor: pointer;
  background-color: #e3f2fd !important;
}

.truncated-alert {
  width: 18rem;
  height: 1.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
}
