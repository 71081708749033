/* .header {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #282828;
}

.logo {
  font-weight: 600;
}

.cid {
  font-family: 'Lexend Deca';
  letter-spacing: 0px;
  color: #ffffff;
}

.logo-overlap {
  letter-spacing: -3px;
}

.systems {
  font-family: 'Lexend Deca';
  letter-spacing: 0px;
  color: #ea1313;
}

.help-desk {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  color: #8892be;
} */

/* .header {
  display: inline-flex;
  position: fixed;
  width: 100%;
  height: 51px;
  z-index: 1;
  padding-left: 60px;
  border-bottom: 1px solid #e0e0e0;
} */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50;
  z-index: 0;
  text-align: center;
}

.header-logo {
  top: 0;
  left: 0;
  width: 60px;
  height: 50;
  z-index: 0;
  text-align: center;
}

.left-header-block {
  display: inline-flex;
  position: fixed;
  left: 60px;
}

.right-header-block {
  display: inline-flex;
  position: fixed;
  right: 0;
}

.new-btn {
  margin-top: 10px;
  margin-left: 10px;
}

.new-dropdown {
  margin-top: -34px;
  margin-left: -21px;
}

.search-bar {
  padding-top: 6px;
  margin-right: 30px;
}

.notification-icon {
  margin-top: 12px;
  margin-right: 30px;
}

.user-icon {
  margin-top: 6px;
  margin-right: 6px;
  padding-top: 6px;
  padding-right: 12px;
  padding-left: 12px;
}

.avatar {
  margin: auto;
  margin-right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 5%;
  background-color: #757575;
}

.initials {
  margin: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}

.logo {
  height: 50px;
  padding-top: 2px;
  padding-bottom: 10px;
}

.cid {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 20px;
  color: #ffffff;
}

.systems {
  margin-top: -4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: #ea1313;
}
