.organization-status-year-table {
  font-size: 0.8rem;
}

.organization-status-month-table {
  font-size: 0.8rem;
}

.project-status-year-table {
  font-size: 0.6rem;
}

.project-status-month-table {
  font-size: 0.6rem;
}
