@keyframes notification {
  0% {
    opacity: 0;
    right: -1rem;
  }

  10% {
    opacity: 0.5;
    right: -0.1rem;
  }

  20% {
    opacity: 1;
    right: 0rem;
  }

  30% {
    opacity: 1;
    right: 0rem;
  }

  40% {
    opacity: 1;
    right: 0rem;
  }

  50% {
    opacity: 1;
    right: 0rem;
  }

  60% {
    opacity: 1;
    right: 0rem;
  }

  70% {
    opacity: 1;
    right: 0rem;
  }

  80% {
    opacity: 1;
    right: 0rem;
  }

  90% {
    opacity: 0.5;
    right: -0.5rem;
  }

  100% {
    opacity: 0;
    right: -1rem;
  }
}

.alert {
  position: fixed;
  top: 51px;
  right: -100rem;
  opacity: 0;
  animation: notification 1s linear;
  z-index: 1;
}

.notification {
  animation: notification 3s linear;
}
