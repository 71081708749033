body {
  background-color: #ecf0f5;
  color: #555555;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Courier New', monospace;
}

ul {
  list-style-type: none;
  margin-bottom: 0rem;
  padding-left: 0rem;
}

a:hover {
  text-decoration: none;
}
