.manage-organizations-page {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.organization-tbody {
  position: fixed;
  overflow-y: scroll;
  right: 60px;
  left: 60px;
  height: 65%;
}

.organization-pagination {
  position: fixed;
  right: 0px;
  bottom: 20px;
  left: 0px;
}

.sortable:hover {
  cursor: default;
  background-color: #c5cae9;
}

.unsortable:hover {
  cursor: default;
}

.inline-block {
  display: inline-block;
}

.member {
  background-color: #ecf0f5;
}

.clickable:hover {
  cursor: default;
  background-color: #e3f2fd;
}
