.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  z-index: 4;
  text-align: center;
  /* background-color: #007acc; */
}

.sidebar {
  height: 50px;
}

.logo {
  height: 50px;
  padding-top: 2px;
  padding-bottom: 10px;
}

.cid {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 20px;
  color: #ffffff;
}

.systems {
  margin-top: -4px;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: #ea1313;
}

.sidebar-icon {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #ffffff;
}

.sidebar-icon:active {
  background-color: #1565c0;
}
