.new-notification-indicator {
  position: absolute;
  top: 8px;
  right: 8px;
}

.clickable-notification:hover {
  cursor: pointer;
  background-color: #757575 !important;
}

.truncated-notification {
  width: 20rem;
  height: 1.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
}
