.conversation-page {
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  right: 0px;
  left: 60px;
  margin-top: 50px;
  height: 90%;
  padding-right: 60px;
  padding-bottom: 60px;
}

.message-display {
  height: 24rem;
  overflow-y: scroll;
  white-space: pre-wrap;
  word-break: break-word;
}

.message-input-bar {
  width: 100%;
}
