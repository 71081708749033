.manage-conversations-page {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.manage-conversations-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.manage-conversations-organization-filter {
  max-width: 200px;
}

.manage-conversations-status-filter {
  max-width: 200px;
}

.manage-conversations-search-bar {
  max-width: 200px;
}

.conversation-tbody {
  position: fixed;
  overflow-y: scroll;
  right: 60px;
  left: 60px;
  height: 65%;
}

.conversation-pagination {
  position: fixed;
  right: 0px;
  bottom: 20px;
  left: 0px;
}

.sortable:hover {
  cursor: default;
  background-color: #c5cae9;
}

.unsortable:hover {
  cursor: default;
}

.clickable:hover {
  cursor: default;
  background-color: #e3f2fd;
}
