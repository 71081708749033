.notifications-page {
  right: 0px;
  left: 60px;
  margin-top: 50px;
  height: 90%;
  padding-right: 60px;
  padding-bottom: 60px;
}

.notification-tbody {
  position: fixed;
  overflow-y: scroll;
  right: 60px;
  left: 60px;
  height: 65%;
}

.clickable-notification-log:hover {
  cursor: pointer;
  background-color: #e3f2fd;
}

.truncated-notification-log {
  width: 75rem;
  height: 1.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
}

.notification-pagination {
  position: fixed;
  right: 0px;
  bottom: 20px;
  left: 0px;
}
