.red-500 {
  color: #f44336;
}

.orange-500 {
  color: #ff9800;
}

.yellow-500 {
  color: #ffeb3b;
}

.green-500 {
  color: #4caf50;
}

.blue-500 {
  color: #2196f3;
}

.gray-500 {
  color: #9e9e9e;
}

.indigo-500 {
  color: #3f51b5;
}

.purple-500 {
  color: #9c27b0;
}

.brown-500 {
  color: #795548;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  -webkit-animation: fade-in 1s 0.2s forwards;
  animation: fade-in 1s 0.2s forwards;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.centered-form {
  width: 24rem;
  padding-top: 3.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.btn-link:hover {
  text-decoration: none;
}

.btn-link:focus {
  text-decoration: none;
}

.back-link-container {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.back-link:hover {
  text-decoration: none;
}

.back-link-icon {
  font-size: 0.8rem;
}
